.selectIdentityProvider {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    text-align: start;
    margin: 2rem auto auto;
}

.selectIdentityProviderTitle {
    margin: 0 !important;
}

.selectIdentityProviderIngress {
    color: #757575;
    margin: 0 0 1rem;
}

.selectIdentityProviderLoginButton {
    width: min-content;
    min-width: 130px;
}