.smsSelfRegistration-container {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
}

.smsSelfRegistration {
    min-width: 400px;
}

@media (max-width: 540px) {
    .smsSelfRegistration {
        min-width: 300px;
    }

}
