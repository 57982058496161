.smsSelfRegistration-container {
  flex-direction: column;
  row-gap: 2rem;
  display: flex;
}

.smsSelfRegistration {
  min-width: 400px;
}

@media (width <= 540px) {
  .smsSelfRegistration {
    min-width: 300px;
  }
}

.selectIdentityProvider {
  text-align: start;
  flex-direction: column;
  gap: 1.5rem;
  margin: 2rem auto auto;
  display: flex;
}

.selectIdentityProviderTitle {
  margin: 0 !important;
}

.selectIdentityProviderIngress {
  color: #757575;
  margin: 0 0 1rem;
}

.selectIdentityProviderLoginButton {
  width: min-content;
  min-width: 130px;
}

.button {
  box-sizing: border-box;
  cursor: pointer;
  border: 0;
  border-radius: 100px;
  flex-direction: row;
  flex: 0;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 100%;
  max-height: 40px;
  padding: 18px 24px;
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  transition: background .2s, color .2s;
  display: flex;
  position: relative;
}

.button .icon {
  font-size: 1.2em;
}

.button.primary {
  background-color: var(--color-primary);
  color: var(--color-on-primary);
}

.button.secondary {
  background-color: var(--color-secondary);
  color: var(--color-on-secondary);
}

.button.outlined-primary {
  background-color: var(--color-background);
  color: var(--color-primary);
  fill: var(--color-primary);
  border: 2px solid var(--color-primary);
}

.button.cancel {
  background-color: var(--color-background);
  border: 2px solid var(--color-error);
  color: var(--color-error);
}

.button.outlined-primary .icon {
  color: var(--color-secondary);
  fill: var(--color-secondary);
}

.button.outlined-primary .icon.primary {
  color: var(--color-primary);
  fill: var(--color-primary);
}

.button .state {
  border-radius: inherit;
  background-color: #0000;
  width: 100%;
  height: 100%;
  transition: background .15s, color .15s;
  position: absolute;
  top: 0;
  left: 0;
}

.button .state:active, .button .state:focus {
  outline: 0;
}

.button .state:hover {
  box-shadow: var(--button-shadow-hover);
}

.button .state:active, .button .state:focus {
  box-shadow: var(--button-shadow-active);
}

.button.primary .state:hover {
  background-color: rgba(var(--color-on-primary_rgb), var(--opacity-hover));
}

.button.primary .state:active, .button.primary .state:focus {
  background-color: rgba(var(--color-on-primary_rgb), var(--opacity-active));
}

.button.outlined-primary .state:hover {
  background-color: rgba(var(--color-primary_rgb), var(--opacity-hover));
}

.button.outlined-primary .state:active, .button.outlined-primary .state:focus {
  background-color: rgba(var(--color-primary_rgb), var(--opacity-active));
}

.button.secondary .state:hover {
  background-color: rgba(var(--color-on-secondary_rgb), var(--opacity-hover));
}

.button.secondary .state:active, .button.secondary .state:focus {
  background-color: rgba(var(--color-on-secondary_rgb), var(--opacity-active));
}

.button[disabled] {
  background-color: var(--color-disabled);
  border-color: var(--color-on-disabled);
  color: var(--color-on-disabled);
}

.button[disabled] .icon.primary {
  color: var(--color-on-disabled);
}

.button[disabled] .state:hover, .button[disabled] .state:active, .button[disabled] .state:focus {
  box-shadow: unset;
  cursor: default;
  background-color: #0000;
}

.button-group {
  flex-direction: column;
  gap: 20px;
  display: flex;
}
/*# sourceMappingURL=index.a7ff708a.css.map */
